.login {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: url(../../assets/images/login_bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_right {
    width: 460px;
    height: 500px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 8%;
  }
  .sign-container {
    border: 2px solid linear-gradient(180deg, #ffffff 0%, #b8deff 100%);
    backdrop-filter: blur(10px);
    background-color: #fff;
    display: flex;
    justify-content: center;
    height: 436px;
    width: 446px;
    border-radius: 4px;
    margin-left: 8%;
    .sign-container-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      img {
        width: 338px;
      }
    }
  }
  .login_footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC;
    color: rgba(81, 122, 160, 1);
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.5px;
    text-align: left;
  }
}
